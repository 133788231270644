import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import animationAnalize from "../../../LottieFiles/analyze.json";
import animationLoader from "../../../LottieFiles/GrayLoader.json";
import SuggestionsScreen from "./suggestionJobsScreen";
import suggestionsService from "../../../Services/suggestionJobsService";
import usersService from "../../../Services/usersService";

const Suggestions = (props) => {
  const [state, setState] = useState({
    loadData: true,
    suggestionType: "intents",
    status: "generated",
    activeTab: "1",
    sessionStorageKey: "activeTab",
    jobNames: [],
    prevFiles: [],
    prevToSliceFiles: [],
    suggestions: [],
    categories: [],
    selectedCategory: [],
    selectedIntents: [],
    allIntents: [],
    page: 1,
    totalPages: 1,
    pageSize: 9,
    pageFiles: 1,
    totalPagesFiles: 1,
    totalRecordsFiles: 0,
    pageSizeFiles: 5,
    searchData: "",
    searchDataPrev: "",
    progress: 0,
    deleteJob: null,
    itemsPerPage: 9,
    changingTab: false,
    discardModalRef: useRef(null),
    createModalRef: useRef(null),
    mergeModalRef: useRef(null),
    dragAndDropRef: useRef(null),
  });

  const navigate = useNavigate();
  const AnalizeOptions = {
    loop: true,
    autoplay: true,
    animationData: animationAnalize,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const statusClassNames = {
    "pending": "fas fa-spinner fa-spin fa-sm",
    "running": "fas fa-spinner fa-spin fa-sm",
    "done": "fas fa-check-circle green fa-sm",
    "cancelled": "fas fa-times-circle red fa-sm",
    "failed": "fas fa-times-circle red fa-sm",
    "created": "fas fa-check-circle fa-sm",
    "marked_as_deleted": "as fa-times-circle red fa-sm",
    null: "fas fa-question-circle fa-sm"
  }

  const workspace = props.workspace.id;
  const user = props.user;

  const [loadData, setLoadData] = useState(true);
  const [status, setStatus] = useState("generated");
  const [activeTab, setActiveTab] = useState("1");
  const sessionStorageKey = "activeTab";
  const [jobNames, setJobNames] = useState([]);
  const [jobIds, setJobIds] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageFiles, setPageFiles] = useState(1);
  const [totalPagesFiles, setTotalPagesFiles] = useState(1);
  const [totalRecordsFiles, setTotalRecordsFiles] = useState(0);
  const [searchData, setSearchData] = useState("");
  const [searchDataPrev, setSearchDataPrev] = useState("");
  const [progress, setProgress] = useState(0);
  const [deleteJob, setDeleteJob] = useState(null);
  const [pageSizeFiles, setPageSizeFiles] = useState(5);
  const [changingTab, setChangingTab] = useState(false);
  const [numJobs, setNumJobs] = useState(0);
  const [tempJobs, setTempJobs] = useState([]);
  const discardModalRef = useRef(null);
  const createModalRef = useRef(null);
  const mergeModalRef = useRef(null);
  const dragAndDropRef = useRef(null);

  useEffect(() => {
    const storedActiveTab = sessionStorage.getItem(sessionStorageKey);
    if (storedActiveTab === "4") {
      setActiveTab(storedActiveTab);
    }
  }, []);

  useEffect(() => {
    if (createModalRef.current && !(createModalRef.current instanceof Modal)) {
      createModalRef.current = new Modal(createModalRef.current, {
        keyboard: false,
      });
    }
    if (discardModalRef.current && !(discardModalRef.current instanceof Modal)) {
      discardModalRef.current = new Modal(discardModalRef.current, {
        keyboard: false,
      });
    }
    if (mergeModalRef.current && !(mergeModalRef.current instanceof Modal)) {
      mergeModalRef.current = new Modal(mergeModalRef.current, {
        keyboard: false,
      });
    }
  }, []);

  const getData = useCallback(
    async () => {
      try {
        setPrevFiles([]);
        const suggestionsRes = await suggestionsService.collect_many(
          {
            page: pageFiles - 1,
            limit: pageSizeFiles,
            workspace_id: workspace
          }
        );
        
        let tempSuggestions = suggestionsRes.data?.resultData || [];
        let totalRecords = suggestionsRes.data?.pageInfo?.totalRecords;
        const tempJobNames = tempSuggestions.map(job => job.job_name);
        setJobNames(tempJobNames);
        setNumJobs(tempSuggestions.length);
  
        setTotalPagesFiles(pageSizeFiles < totalRecords ? Math.ceil(totalRecords / pageSizeFiles) : 1);
        setTotalRecordsFiles(totalRecords);

        const fileNames = await Promise.all(
          tempSuggestions.map(async (file) => {
            // if (!file || !file.created) return file;
            // let userRes = await usersService.collectOne({ user_id: file.created.by });
            // let first_name = userRes?.first_name || "Unknown";
            // let first_surname = userRes?.first_surname || "User";
            file.status_className = file.status in statusClassNames? statusClassNames[file.status]: statusClassNames[null];
            return {
              ...file,
              created: {
                ...file.created,
                user_name: file.created_by??"Unknown User",
              },
            };
          })
        );

        setPrevFiles(fileNames);
      } catch (err) {
        console.log("Error fetching data:", err);
      } finally {
        setLoadData(false);
      }
    },
    [workspace, pageFiles]
  );

  async function handleDelete(file) {
      await suggestionsService.delete(file._id)
      .then((res) => {
        let modal = new Modal(document.getElementById("DeleteFileModal"));
        modal.show();
        if (res.data.code === 200) {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getData(pageFiles - 1);
      });
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    if (tab === "4") {
        sessionStorage.setItem(sessionStorageKey, tab);
    } else {
        sessionStorage.removeItem(sessionStorageKey);
    }
};

function onChangeOption(value) {
    setPage(1);
}

const handleSearchSubmit = (event) => {
    handleSearch();
    event.preventDefault();
};

const handleSearchInput = (event) => {
    setSearchDataPrev(event.target.value);
};

const handleSearch = () => {
    setPage(1);
    setSearchData(searchDataPrev);
};

function handleClearSearchChange() {
    setPage(1);
    setSearchDataPrev("");
    setSearchData("");
}

function getSlicedRequests(tempRequestsPage, pageN) {
  const nonDeletedFiles = tempRequestsPage.filter(file => !file.deleted);
  const deletedFiles = tempRequestsPage.filter(file => file.deleted);
  const combinedFiles = [...nonDeletedFiles, ...deletedFiles];
  window.alert("files")
  console.log("NonDeletedFiles", nonDeletedFiles);
  console.log("DeletedFiles", deletedFiles);
  return combinedFiles.slice((pageN - 1) * pageSizeFiles, pageN * pageSizeFiles);
}

const handleFilePageChange = async (page) => {
    setPageFiles(page);
};

useEffect(() => {
  getData();
}, [pageFiles, getData]);

const splitArray = (array, itemsPerPage) => {
    const splitted = [];
    if (array) {
        for (let i = 0; i < array.length; i += itemsPerPage) {
            const chunk = array.slice(i, i + itemsPerPage);
            splitted.push(chunk);
        }
    }
    return splitted;
};

const toggleTab = async (tab) => {
    toggle(tab);
    if (tab === "1") {
        setChangingTab(true);
        status !== "generated" ? setStatus("generated") : getData();
    }
    if (tab === "3") {
        setChangingTab(true);
        status !== "suggestions_rejected" ? setStatus("suggestions_rejected") : getData();
    }
    if (["1", "3"].includes(tab)) handleClearSearchChange();
};

useEffect(() => {
    if (loadData) {
        getData();
    }
}, [loadData, getData, status]);

const formatOptions = (categories) => {
    let options = [];
    for (var i = 0; i < categories.length; i++) {
        options.push({
            value: categories[i]._id,
            label: categories[i].name.value,
            _id: categories[i]._id,
        });
    }
    return options;
};

return <SuggestionsScreen _this={{
    props,
    page,
    totalPages,
    activeTab,
    numJobs,
    tempJobs,
    changingTab,
    activeTab,
    totalRecordsFiles,
    prevFiles,
    totalPages,
    totalPagesFiles,
    jobNames,
    jobIds,
    pageFiles,
    totalPagesFiles,
    toggle,
    toggleTab,
    handleFilePageChange,
    setDeleteJob,
    handleDelete,
    navigate

}} />;
};

const mapStateToProps = (state) => {
    return {
        workspace: state.workspace,
        user: state.user,
        role: state.user.role,
    };
};

export default connect(mapStateToProps)(Suggestions);