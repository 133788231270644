import React from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink} from "reactstrap";
import Header from "../../../Components/Header/headerView";
import Footer from "../../../Components/Footer/footer";
import Menu from "../../../Components/Menu/menu";
import NDSTooltip from "../../../Components/Tooltip/ndsTooltip";
import Paginator from "../../../Components/Paginator/paginator";
import classNames from "classnames";
import AnalyzingComponent from "./analyzingComponent";
import * as dateService from "../../../Utils/format/date";
import * as stringService from "../../../Utils/format/string";
import SuggestionsEmpty from "./suggestionJobsEmpty";
import { DeleteFileModal } from "../../../Utils/modals";

import Lottie from 'react-lottie';
import animationLoader from '../../../LottieFiles/GrayLoader.json';

export const SuggestionsScreen = ({_this}) => {
  if (!_this || !_this.props) {
    return (
    <div>
      <p>Error: _this is undefined or has no props</p>
      <SuggestionsEmpty />
    </div>);
    }

  const LoaderOptions = {
    loop: true,
    autoplay: false,
    animationData: animationLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

if (_this.numJobs > 0){
  return (
    <div className={_this.props.className}>
      <DeleteFileModal />
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={5} />
        </div>
        <div className="container px-20 mb-50">
          <div className="row">
            <div className="col-12 my-20">
              <div className="row mt-50 mb-10">
                <p className="h1 -r">Suggestions</p>
              </div>
              <Nav tabs className="mt-40">
                <NavItem>
                  <NavLink
                    className={_this.activeTab === "1" ? "active" : "inactive"}
                    onClick={() => {

                      if (_this.activeTab !== "1"){
                        _this.toggleTab("1");
                      }

                    }}
                  >
                    <span>
                      <p className="icon">
                        <i className="fa-solid fa-upload"></i>
                      </p>
                      <p className="h3 -b">UPLOAD FILES FOR ANALYSIS</p>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classNames({ active: _this.activeTab === "2" })}
                    onClick={() => {
                      if (_this.activeTab !== "2"){
                        _this.toggleTab("2");
                      }
                    }}
                  >
                    <span>
                      <p className="icon">
                        <i className="fa-solid fa-boxes-packing"></i>
                      </p>
                      <p className="h3 -b">ANALYZED FILES</p>
                    </span>
                  </NavLink>
                  {_this.totalRecordsFiles > 0 && _this.totalRecordsFiles && (
                    <span className="total-counter">
                      <span className="-notif">{_this.totalRecordsFiles}</span>
                    </span>
                  )}
                </NavItem>
              </Nav>
            </div>
          </div>
          <TabContent activeTab={_this.activeTab}>
            <TabPane tabId="1">
            <AnalyzingComponent jobNames={_this.jobNames}></AnalyzingComponent>
            </TabPane>
            <TabPane tabId="2">
              <div className="row">
                <div className="col-12 mt-20">
                  <table className="bg-white">
                    <thead>
                      <tr>
                        <th>
                          <p className="d-flex align-items-center w-100 mx-20">
                            File
                          </p>
                        </th>
                        <th>
                          <p className="d-flex align-items-center w-100">
                            Status
                          </p>
                        </th>
                        <th>
                          <p className="d-flex align-items-center w-100">
                            Upload by
                          </p>
                        </th>
                        <th>
                          <p className="d-flex align-items-center w-100">
                            Date
                          </p>
                        </th>
                        <th>
                          <p className="d-flex align-items-center w-100">
                            Total
                          </p>
                          <p className="d-flex align-items-center w-100">
                            Requests
                          </p>
                        </th>
                        <th>
                          <p className="d-flex align-items-center w-100">
                            Intents
                          </p>
                          <p className="d-flex align-items-center w-100">
                            Accepted
                          </p>
                        </th>
                        <th>
                          <p className="d-flex align-items-center w-100">
                            Intents
                          </p>
                          <p className="d-flex align-items-center w-100">
                            Discarded
                          </p>
                        </th>
                        <th>
                          <p className="d-flex align-items-center w-100">
                            Entities
                          </p>
                          <p className="d-flex align-items-center w-100">
                            Accepted
                          </p>
                        </th>
                        <th>
                          <p className="d-flex align-items-center w-100">
                            Entities
                          </p>
                          <p className="d-flex align-items-center w-100">
                            Discarded
                          </p>
                        </th>
                        <th>
                          <p className="d-flex align-items-center justify-content-center w-100">
                            Actions
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_this.prevFiles.length > 0 ? (
                        _this.prevFiles?.map((file, index) => {
                          return (
                            <tr className="read-file" key={index}>
                              <td className="d-flex mx-20">
                              {file.deleted ? (
                                  <b>
                                    <s>{file.filename}</s>
                                  </b>
                                ) : file.status === "cancelled" || file.status === "failed" ? (
                                  <b>
                                    <s>{file.filename}</s>
                                  </b>
                                ) : file.status === "unknown" || file.status === "pending" || file.status === "running" || file.status === "starting" || file.status === "created" ? (
                                  <b>
                                    <p>{file.filename}</p>
                                  </b>
                                ) : (
                                  <a
                                    className="link"
                                    onClick={() =>
                                      _this.navigate("/requests", {
                                        state: { tab: "4" },
                                        job_name: file.job_name,
                                      })
                                    }
                                  >
                                    <b>{file.filename}</b>
                                  </a>
                                )}
                              </td>
                              <td>
                              {file.deleted ? (
                                <b><p>Deleted</p></b>
                              ) : (
                                    <b key={file.status_className}>
                                      <div>
                                          <i className={file.status_className}></i> {stringService.titleCase(file.status)}
                                      </div>
                                    </b>
                              )}
                              </td>
                              <td>{file.created.user_name}</td>
                              <td>
                                {file?.created?.at?new Date(
                                  dateService.convertIsoUtcToLocal(file.created.at)
                                ).toLocaleString():"Undefined"}
                              </td>
                              <td>
                                <b>
                                  {(file?.execution_summary?.new_intents?.success || 0) +
                                    (file?.execution_summary?.new_entities?.success || 0)}
                                </b>
                              </td>
                              <td>{file?.execution_summary?.new_intents?.success ?? 0}</td>
                              <td>{file?.execution_summary?.new_intents?.failed ?? 0}</td>
                              <td>{file?.execution_summary?.new_entities?.success ?? 0}</td>
                              <td>{file?.execution_summary?.new_entities?.failed ?? 0}</td>
                              <td
                                className="text-c"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteFileModal"
                              >
                                {file.status !== "marked_as_deleted" ? (
                                  <button
                                    type="button"
                                    className="red w-100"
                                    onClick={() => {
                                      _this.handleDelete(file);
                                    }}
                                  >
                                    <NDSTooltip
                                      icon="fa-solid fa-trash-can red"
                                      target="trash"
                                      tooltipMessage="Delete"
                                      placement="bottom"
                                    ></NDSTooltip>
                                  </button>
                                ) : (
                                  <button className="py-3 opacity-0" disabled></button>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={11}>
                            {/* <span className="d-flex justify-content-center">
                              No Analyzed files found
                            </span> */}
                            <Lottie
                              options={LoaderOptions}
                              height={35}
                              width={35}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row mt-20">
                  {!_this.changingTab && <Paginator
                    pages={_this.totalPagesFiles}
                    currentPage={_this.pageFiles}
                    setPage={_this.handleFilePageChange}
                  />}
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
      <Footer />
    </div>
  );
}
else {
  return (
    <div>
      <SuggestionsEmpty />
    </div>
  );
}
};

export default SuggestionsScreen;
